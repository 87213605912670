
import CloudFun, {
  Condition,
  Operator,
  onMounted,
  defineComponent,
  PropType,
  ref,
  Sorting,
  SortOrder
} from "@cloudfun/core";
import { VxeTablePropTypes } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    SelectBox
  },
  props: {
    modelValue: Array as PropType<any[]>,
    productId: String
  },
  setup() {
    const model = CloudFun.current?.model;
    const attributeGrid = ref({});
    const groupValues = ref<any>([]);
    const categoryId = ref(0);
    const values = ref<any>([]);
    const attributes = ref<any>([]);
    const rowStyle: VxeTablePropTypes.RowStyle = ({ row }) => {
      if (row.Attribute.Required) {
        return {
          backgroundColor: "#F000003F"
        };
      }
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    return {
      attributeGrid,
      rowStyle,
      groupValues,
      values,
      categoryId,
      attributes,
      categorySelectOptions
    };
  },
  watch: {
    // modelValue(current: any) {
    //   console.log("current", current);
    //   if (current !== this.attributes) {
    //     console.log("current", current);
    //     this.attributes = current;
    //   }
    // }
    modelValue(current: any) {
      console.log(
        "🚀 ~ file: CategoryAttributeGrid.vue ~ line 215 ~ modelValue ~ current",
        current
      );

      if (current !== this.values) {
        if (Array.isArray(this.modelValue)) {
          for (const item of this.modelValue) {
            if (item.AttributeValue) {
              const group = this.groupValues.find(
                (e: any) => e.Attribute.Id === item.AttributeValue.AttributeId
              );
              if (group) {
                group.AttributeValues = [];
                group.SelectedValues = group.Multiple ? [] : undefined;
                group.AttributeValues.push(item.AttributeValue);
                if (group.Attribute.Selectable) {
                  if (group.Attribute.Multiple)
                    group.SelectedValues.push(item.AttributeValueId);
                  else group.SelectedValues = item.AttributeValueId;
                }
              }
            }
          }
        }
        for (const group of this.groupValues) {
          if (!group.AttributeValues.length) {
            if (group.Attribute.Required) {
              if (group.Attribute.Selectable) {
                group.AttributeValues.push(group.Attribute.Values[0]);
                if (group.Multiple)
                  group.SelectedValues.push(group.Attribute.Values[0].Id);
                else group.SelectedValues = group.Attribute.Values[0].Id;
              } else
                group.AttributeValues.push({ AttributeId: group.Attribute.Id });
            } else
              group.AttributeValues.push({ AttributeId: group.Attribute.Id });
          }
        }
        this.computeValues();
        this.$emit("update:modelValue", this.values);
      }
    }
  },
  mounted() {
    if (this.modelValue) {
      this.groupValues = this.modelValue.map((e: any) => {
        return {
          Attribute: {
            Id: e.AttributeValue.Attribute.Id,
            Name: e.AttributeValue.Attribute.Name,
            Code: e.AttributeValue.Attribute.Code,
            Ordinal: e.AttributeValue.Attribute.Ordinal,
            Required: e.AttributeValue.Attribute.Required,
            Selectable: e.AttributeValue.Attribute.Selectable,
            Values: e.AttributeValue.Attribute.Values,
            Multiple: e.AttributeValue.Attribute.Multiple
          },
          AttributeValues: [],
          SelectedValues: e.Multiple ? [] : undefined
        };
      });

      if (Array.isArray(this.modelValue)) {
        for (const item of this.modelValue) {
          if (item.AttributeValue) {
            const group = this.groupValues.find(
              (e: any) => e.Attribute.Id === item.AttributeValue.AttributeId
            );
            if (group) {
              group.AttributeValues.push(item.AttributeValue);
              if (group.Attribute.Selectable) {
                if (group.Attribute.Multiple)
                  group.SelectedValues.push(item.AttributeValueId);
                else group.SelectedValues = item.AttributeValueId;
              }
            }
          }
        }
      }
      for (const group of this.groupValues) {
        if (!group.AttributeValues.length) {
          if (group.Attribute.Required) {
            if (group.Attribute.Selectable) {
              group.AttributeValues.push(group.Attribute.Values[0]);
              if (group.Multiple)
                group.SelectedValues.push(group.Attribute.Values[0].Id);
              else group.SelectedValues = group.Attribute.Values[0].Id;
            } else
              group.AttributeValues.push({ AttributeId: group.Attribute.Id });
          } else
            group.AttributeValues.push({ AttributeId: group.Attribute.Id });
        }
      }
      this.computeValues();
      this.$emit("update:modelValue", this.values);
    }
  },
  methods: {
    computeValues() {
      this.values = [];
      for (const group of this.groupValues) {
        for (const value of group.AttributeValues) {
          this.values.push({
            AttributeValueId: value.Id,
            AttributeValue: value
          });
        }
      }
    },
    onChange(attributeId: any) {
      const group = this.groupValues.find(
        (e: any) => e.Attribute.Id === attributeId
      );
      if (group.Attribute.Selectable) {
        if (Array.isArray(group.SelectedValues)) {
          group.AttributeValues = group.SelectedValues.map((value: any) => {
            return {
              AttributeId: group.Attribute.Id,
              Id: value
            };
          });
        } else {
          group.AttributeValues = [
            {
              AttributeId: group.Attribute.Id,
              Id: group.SelectedValues
            }
          ];
        }
      }
      this.computeValues();
      this.$emit("update:modelValue", this.values);
    },
    applyCategory() {
      if (this.categoryId) {
        if (this.attributes && this.attributes.length > 0) {
          if (!confirm("已存在屬性資料，套用將全部清空！是否確認套用？"))
            return;
        }

        const params = {
          condition: new Condition(
            "CategoryId",
            Operator.Equal,
            this.categoryId.toString()
          ),
          sortings: new Sorting("Ordinal", SortOrder.Ascending)
        };

        this.$model.dispatch("attribute/query", params).then(
          payload => {
            this.groupValues = payload.map((e: any) => {
              return {
                Attribute: {
                  Id: e.Id,
                  Name: e.Name,
                  Code: e.Code,
                  Ordinal: e.Ordinal,
                  Required: e.Required,
                  Selectable: e.Selectable,
                  Values: e.Values,
                  Multiple: e.Multiple
                },
                AttributeValues: [],
                SelectedValues: e.Multiple ? [] : undefined
              };
            });

            if (Array.isArray(this.modelValue)) {
              for (const item of this.modelValue) {
                if (item.AttributeValue) {
                  const group = this.groupValues.find(
                    (e: any) =>
                      e.Attribute.Id === item.AttributeValue.AttributeId
                  );
                  if (group) {
                    group.AttributeValues.push(item.AttributeValue);
                    if (group.Attribute.Selectable) {
                      if (group.Attribute.Multiple)
                        group.SelectedValues.push(item.AttributeValueId);
                      else group.SelectedValues = item.AttributeValueId;
                    }
                  }
                }
              }
            }
            for (const group of this.groupValues) {
              if (!group.AttributeValues.length) {
                if (group.Attribute.Required) {
                  if (group.Attribute.Selectable) {
                    group.AttributeValues.push(group.Attribute.Values[0]);
                    if (group.Multiple)
                      group.SelectedValues.push(group.Attribute.Values[0].Id);
                    else group.SelectedValues = group.Attribute.Values[0].Id;
                  } else
                    group.AttributeValues.push({
                      AttributeId: group.Attribute.Id
                    });
                } else
                  group.AttributeValues.push({
                    AttributeId: group.Attribute.Id
                  });
              }
            }
            this.computeValues();

            // this.attributes = payload.map((el: any) => {
            //   return {
            //     Id: el.Id,
            //     Name: el.Name,
            //     Code: el.Code,
            //     Ordinal: el.Ordinal,
            //     Selectable: el.Selectable,
            //     Value: "",
            //     Values: el.Values,
            //     Multiple: el.Multiple,
            //     Required:el.Required
            //   };
            // });

            this.$emit("update:modelValue", this.values);
          },
          failure => {
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        );
      }
    },
    removeItem(row: any) {
      if (row && row.Id && confirm("確定刪除") && this.attributes) {
        this.attributes = this.attributes.filter((el: any) => el.Id !== row.Id);
        this.$emit("update:modelValue", this.values);
      }
    }
  }
});
