
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
// import ProductAttributeGrid from "@/cloudfun/components/ProductAttributeGrid.vue";
import CategoryAttributeGrid from "@/cloudfun/components/CategoryAttributeGrid.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import FileManager from "@/global-components/file-manager/Main.vue";
import FileManagerIcon from '!!raw-loader!@/assets/images/file.svg'; // eslint-disable-line

const isModalPopup = ref(false);
let descriptionEditor: any = null;
class FileManagePlugin extends ClassicEditor.Plugin {
  init() {
    // console.log('File Manager was initialized.');
    descriptionEditor = this.editor;
    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.

    descriptionEditor.ui.componentFactory.add('filemanage', () => {
      // The button will be an instance of ButtonView.
      const ceditor = this.editor
      const button = new ClassicEditor.ButtonView();
      const linkCommand = ceditor.commands.get("link");

      button.set({
        label: 'File Manager',
        tooltip: '開啟檔案總管插入圖片',
        icon: FileManagerIcon
      });
      // Execute a callback function when the button is clicked.
      button.bind('isEnabled').to(linkCommand);
      button.on('execute', () => {
        isModalPopup.value = true;
      });

      return button;
    });
  }
}
export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox,
    CategoryAttributeGrid,
    SliderEditor,
    FileManager
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const characteristicEditor = ref();
    const specificationEditor = ref();
    const productAttributeValues = ref([]);
    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" }
    ];

    const gridOptions: GridOptions = {
      title: "產品資料",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "產品清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "產品清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "120",
          fixed: "left"
        },
        {
          field: "Barcode",
          title: "條碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "130"
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Category.Name",
          title: "主類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Brand.Name",
          title: "品牌",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Country.Name",
        //   title: "產地",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("product/query", params)
          : undefined,
        queryAll: model
          ? params => model.dispatch("product/query", params)
          : undefined,
        save: model
          ? params => model.dispatch("product/save", params)
          : undefined
      },
      modalConfig: { width: "98%", height: "98%", showFooter: true }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "CategoryId",
          title: "類別",
          span: 12,
          slots: { default: "column-category-id" }
        },
        {
          field: "Number",
          title: "編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "未輸入時由系統產生" }
          }
        },
        {
          field: "BrandId",
          title: "品牌",
          span: 12,
          slots: { default: "column-brand-id" }
        },
        {
          field: "Barcode",
          title: "條碼",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入條碼" } }
        },
        // {
        //   field: "CountryId",
        //   title: "產地",
        //   span: 12,
        //   slots: { default: "column-country-id" }
        // },
        {
          field: "SalePice",
          title: "售價",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入價格" }
          }
        },
        {
          field: "PurchasePrice",
          title: "定價",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入價格" }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Introduction",
          title: "商品簡述",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入商品簡述" }
          }
        }
        // {
        //   field: "StartDate",
        //   title: "開始日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "date", placeholder: "請輸入日期" }
        //   }
        // },
        // {
        //   field: "EndDate",
        //   title: "結束日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "date", placeholder: "請輸入日期" }
        //   }
        // }
      ],
      rules: {
        Name: [{ required: true }],
        CategoryId: [{ required: true }],
        BrandId: [{ required: true }],
        // CountryId: [{ required: true }],
        PurchasePrice: [{ type: "number", required: true, message: "定價" }],
        SalePice: [{ type: "number", required: true, message: "售價" }]
      }
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const brandSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇品牌",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("brand/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("brand/find", value), // eslint-disable-line
        query: params => model!.dispatch("brand/query", params) // eslint-disable-line
      }
    };

    const countrySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇產地",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("country/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("country/find", value), // eslint-disable-line
        query: params => model!.dispatch("country/query", params) // eslint-disable-line
      }
    };

    const tagsSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: value => model!.dispatch("tag/find", value), // eslint-disable-line
        query: params => model!.dispatch("tag/query", params), // eslint-disable-line
        insert: row => model!.dispatch("tag/insert", row) // eslint-disable-line
      }
    };

    const editorOptions = {
      editor: ClassicEditor.Editor,
      config: {
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [UploadAdapterPlugin, "MediaEmbed"],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            // "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    const editorOptions2 = {
      editor: ClassicEditor.Editor,
      config: {
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [UploadAdapterPlugin, "MediaEmbed"],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    const editorOptions3 = {
      editor: ClassicEditor.Editor,
      config: {
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [UploadAdapterPlugin, "MediaEmbed", FileManagePlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "filemanage",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Ordinal",
          title: "順序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }]
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      categorySelectOptions,
      brandSelectOptions,
      countrySelectOptions,
      tagsSelectOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      editorOptions,
      sliderWidth: ref("100%"),
      pictureFormOptions,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      showSpecification: ref(true),
      showCharacteristic: ref(true),
      characteristicEditor,
      specificationEditor,
      editorOptions2,
      editorOptions3,
      productAttributeValues,
      isModalPopup: isModalPopup
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.Specification = {
        Content: ""
      };
      row.Characteristic = {
        Content: ""
      };
      row.Description = {
        Content: ""
      };
      row.ProductAttributeValues = [];
      this.productAttributeValues = [];

      console.log("🚀 ~ file: Main.vue ~ line 914 ~ onGridAdd ~ row", row);
      callback();
    },
    insertImage(imgArray: any) {
      console.log("insertImage")
      const images = imgArray.map(function (value: any) {
        return value.dataItem.url;
      })
      isModalPopup.value = false;
      descriptionEditor.execute('insertImage', { source: images });
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("product/find", row.Id)
        : undefined;
      console.log(
        "🚀 ~ file: Main.vue ~ line 1091 ~ onGridEdit ~ entity",
        entity
      );

      if (entity) {
        if (entity.Specification.Content === null) {
          entity.Specification.Content = "";
        }

        if (entity.Description.Content === null) {
          entity.Description.Content = "";
        }

        if (entity.Characteristic.Content === null) {
          entity.Characteristic.Content = "";
        }

        Object.assign(row, entity);
      }

      console.log("🚀 ~ file: Main.vue ~ line 1103 ~ onGridEdit ~ row", row);

      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = this.grid.editingRow.PictureAlbumId;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch("picture/delete", image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      await this.$model.dispatch("picture/save", params).then(
        () => {
          this.$model
            .dispatch("pictureAlbum/find", this.grid.editingRow.PictureAlbumId)
            .then(
              entity => {
                entity.Pictures.forEach((picture: any) => {
                  delete picture.$id;
                  delete picture.Album;
                });
                const sliderEditor: any = this.$refs.sliderEditor;
                sliderEditor.reload(
                  entity.Pictures.sort(
                    (a: any, b: any) => a.Ordinal - b.Ordinal
                  )
                );
              },
              reason => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason
                });
              }
            );
        },
        reason => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    }
  }
});
